export default [
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92538492, 30.31796118],
    },
    properties: {
      imageClass: 'search-map-result-view',
      balloonContent: ' Смузийная Ешь Фреш',
      hintContent: '',
      balloonContentFooter:
        'Московский проспект 4а <a href="https://vegago.ru/cafe/smuzijnaya-esh-fresh.html" target="_blank">Подробнее</a>',
      clusterCaption: '',
      iconContent: ' Смузийная Ешь Фреш',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92564646, 30.34168568],
    },
    properties: {
      imageClass: 'search-map-result-view',
      balloonContent: '',
      hintContent: '',
      clusterCaption: '',
      balloonContentFooter:
        '<p><a href="https://vegago.ru/cafe/falafelnaya-falafel-king/" target="_blank">Подробнее</a></p>',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92528572, 30.35816977],
    },
    properties: {
      imageClass: 'search-map-result-view',
      balloonContent: 'Veggie box food&bakery',
      hintContent: '',
      clusterCaption: '',
      balloonContentFooter:
        'Лиговский проспект, 50 <p><a href="https://vegago.ru/cafe/veggie-box.html" target="_blank">Подробнее</a></p>',
      iconContent: 'Veggie box food&bakery',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.94015416, 30.32607296],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '47',
      balloonContent:
        'Hood - vegan street food<br/>Конюшенная пл.д 2в<p><a href="https://vegago.ru/cafe/hood-vegan-street-food.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Hood - vegan street food<br/>Конюшенная пл.д 2в',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.938112, 30.34659],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '48',
      balloonContent:
        'Вегетарианское Харе Кришна кафе (Jiva Burgers)<br/>Белинского 9, 4 этаж<p><a href="https://vegago.ru/cafe/vegetarianskoe-xare-krishna-kafe-jiva-burgers.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent:
        'Вегетарианское Харе Кришна кафе (Jiva Burgers)<br/>Белинского 9, 4 этаж',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.921972388305, 30.355925559023],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '51',
      balloonContent:
        'Веган кафе «Вилки не против»<br/>Лиговский пр. 74<p><a href="https://vegago.ru/cafe/vegan-kafe-vilki-ne-protiv.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Веган кафе «Вилки не против»<br/>Лиговский пр. 74',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.939972695809, 30.344868146389],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '52',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/bodryij-nut-set-falafelnyix/" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92766205, 30.35048019],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '54',
      balloonContent:
        'Ресторан «RA Family»<br/>Кузнечный пер. 6<p><a href="https://vegago.ru/cafe/restoranra-family.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Ресторан «RA Family»<br/>Кузнечный пер. 6',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93342407, 30.32801332],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '58',
      balloonContent:
        'Кафе «Дядя Фади»<br/>ул. Думская ул. 5/22, кв. 68<p><a href="https://vegago.ru/cafe/kafe-dyadya-fadi.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Кафе «Дядя Фади»<br/>ул. Думская ул. 5/22, кв. 68',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93250889, 30.32204851],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '60',
      balloonContent:
        'Кафе «Samadeva»<br/>ул. Казанская, 10<p><a href="https://vegago.ru/cafe/kafe-baraka.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Кафе «Samadeva»<br/>ул. Казанская, 10',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.939603354512, 30.272028954893],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '61',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/kafe-ukrop/" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.95715136, 30.31439486],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '62',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/set-vegetarianskix-kafe-troiczkij-most/" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92837898, 30.32248869],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '63',
      balloonContent:
        'Вегетарианское заведение «Рада и К»<br/>ул. Гороховая 36<p><a href="https://vegago.ru/cafe/vegetarianskoe-zavedenie-rada-i-k.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Вегетарианское заведение «Рада и К»<br/>ул. Гороховая 36',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.94219591, 30.34130839],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '64',
      balloonContent:
        'Кафе «Ботаника»<br/>ул. Пестеля 7<p><a href="https://vegago.ru/cafe/kafe-botanika.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Кафе «Ботаника»<br/>ул. Пестеля 7',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.926260743021, 30.346756355225],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '66',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/kafe-kashmir.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93643092, 30.36268829],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '68',
      balloonContent:
        'Кафе & Арт пространство «Ауровилль»<br/>ул.Радищева 5<p><a href="https://vegago.ru/cafe/kafe-i-art-prostranstvo-aurovill.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Кафе & Арт пространство «Ауровилль»<br/>ул.Радищева 5',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93938791, 30.34477589],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '69',
      balloonContent:
        'Вегетарианское кафе «ПРЕКРАСНАЯ ЗЕЛЁНАЯ»<br/>Моховая улица, 41<p><a href="https://vegago.ru/cafe/vegetarianskoe-kafe-prekrasnaya-zelyonaya.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent:
        'Вегетарианское кафе «ПРЕКРАСНАЯ ЗЕЛЁНАЯ»<br/>Моховая улица, 41',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92278294, 30.30518713],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '70',
      balloonContent:
        'Дзен-бургерная в СПб | ZEN Vegan Burger<br/>ул. Большая Подьяческая 29<p><a href="https://vegago.ru/cafe/dzen-burgernaya-zen-vegan-burger.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent:
        'Дзен-бургерная в СПб | ZEN Vegan Burger<br/>ул. Большая Подьяческая 29',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92947463, 30.31273298],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '71',
      balloonContent:
        'Ресторан здорового питания «Prana bar»<br/>ул. Казанская 31<p><a href="https://vegago.ru/cafe/prana-bar-kafe.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent:
        'Ресторан здорового питания «Prana bar»<br/>ул. Казанская 31',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92619208, 30.3466893],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '89',
      balloonContent:
        'Кафе «Mantra Bar»<br/>ТРЦ «Великан Парк», Александровский парк 4/3<p><a href="https://vegago.ru/cafe/kafe-mmantra-bar.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent:
        'Кафе «Mantra Bar»<br/>ТРЦ «Великан Парк», Александровский парк 4/3',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93950511, 30.27212015],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '140',
      balloonContent:
        '"Квартира" (Кафе "Укроп") 7 линия В.О., 30<p><a href="https://vegago.ru/cafe/kafe-ukrop/kvartira-(kafe-ukrop)-7-liniya-v.o.,-30.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '"Квартира" (Кафе "Укроп") 7 линия В.О., 30',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93632724, 30.32535431],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '141',
      balloonContent:
        '"Лето" (Кафе "Укроп") Малая Конюшенная улица, 14<p><a href="https://vegago.ru/cafe/kafe-ukrop/leto-(kafe-ukrop)-malaya-konyushennaya-ulicza,-14.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '"Лето" (Кафе "Укроп") Малая Конюшенная улица, 14',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92789201, 30.35286072],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '142',
      balloonContent:
        '"Кафе-бистро" (Кафе "Укроп") улица Марата, 23<p><a href="https://vegago.ru/cafe/kafe-ukrop/kafe-bistro-(kafe-ukrop)-ulicza-marata,-23.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '"Кафе-бистро" (Кафе "Укроп") улица Марата, 23',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.94140267, 30.36103539],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '143',
      balloonContent:
        '"Небо внутри" (Кафе "Укроп") улица Восстания, 47<p><a href="https://vegago.ru/cafe/kafe-ukrop/nebo-vnutri-(kafe-ukrop)-ulicza-vosstaniya,-47.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '"Небо внутри" (Кафе "Укроп") улица Восстания, 47',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.925589910975, 30.34179296836],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '513',
      balloonContent:
        'Falafel King<br> Загородный проспект, 22<p><a href="https://vegago.ru/cafe/falafelnaya-falafel-king/falafel-king-zagorodnyij-prospekt,-22.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Falafel King<br> Загородный проспект, 22',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92598917, 30.31982069],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '514',
      balloonContent:
        'Falafel King<br> Ефимова 3 (Сеной рынок)<p><a href="https://vegago.ru/cafe/falafelnaya-falafel-king/falafel-king-efimova-3-(senoj-ryinok).html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Falafel King<br> Ефимова 3 (Сеной рынок)',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93982062, 30.34481182],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '516',
      balloonContent:
        'БобКульт ул. Моховая 39<p><a href="https://vegago.ru/cafe/bodryij-nut-set-falafelnyix/bodryij-nut-ul.-moxovaya-39.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'БобКульт ул. Моховая 39',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.94173619, 30.27973786],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '517',
      balloonContent:
        'БобКульт 7-ая линия В.О, д.40<p><a href="https://vegago.ru/cafe/bodryij-nut-set-falafelnyix/bodryij-nut-7-aya-liniya-v.o,-d.40.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'БобКульт 7-ая линия В.О, д.40',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.9576379, 30.3343734],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '518',
      balloonContent:
        'Сеть вегетарианских кафе «Троицкий мост»<br/> ул.Куйбышева д. 33 <p><a href="https://vegago.ru/cafe/set-vegetarianskix-kafe-troiczkij-most/set-vegetarianskix-kafe-«troiczkij-most»-kronverkskij-pr.,-35.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent:
        'Сеть вегетарианских кафе «Троицкий мост»<br/> ул.Куйбышева д. 33 ',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93857206, 30.32000933],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '519',
      balloonContent:
        'Сеть вегетарианских кафе «Троицкий мост»<br/>наб. реки Мойки 30<p><a href="https://vegago.ru/cafe/set-vegetarianskix-kafe-troiczkij-most/set-vegetarianskix-kafe-«troiczkij-most»-mojka-30.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent:
        'Сеть вегетарианских кафе «Троицкий мост»<br/>наб. реки Мойки 30',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.94275027, 30.32122206],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '520',
      balloonContent:
        'Сеть вегетарианских кафе «Троицкий мост»<br/>Миллионная ул. 25<p><a href="https://vegago.ru/cafe/set-vegetarianskix-kafe-troiczkij-most/set-vegetarianskix-kafe-«troiczkij-most»6-liniya-v.o.-27.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent:
        'Сеть вегетарианских кафе «Троицкий мост»<br/>Миллионная ул. 25',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93681859, 30.31857203],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '524',
      balloonContent:
        'Вегетарианское кафе БРАТ, Большая Морская, 10<p><a href="https://vegago.ru/cafe/vegetarianskoe-kafe-brat.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Вегетарианское кафе БРАТ, Большая Морская, 10',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.94066799, 30.34117364],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '525',
      balloonContent:
        'Веган-кафе Fika, Наб. Фонтанки 20, Голицын лофт<p><a href="https://vegago.ru/cafe/vegan-kafe-fika.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Веган-кафе Fika, Наб. Фонтанки 20, Голицын лофт',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92857737, 30.3020071],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '526',
      balloonContent:
        'Индийское вегетарианское кафе ЛАОР, Прачечный пер. д.10<p><a href="https://vegago.ru/cafe/indijskoe-vegetarianskoe-kafe-laor.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Индийское вегетарианское кафе ЛАОР, Прачечный пер. д.10',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92520004, 30.31887746],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '563',
      balloonContent:
        'BerryБлин • Vegan Food, Московский проспект 4А к1<p><a href="https://vegago.ru/cafe/berryblin-vegan-food.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'BerryБлин • Vegan Food, Московский проспект 4А к1',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.94523351, 30.34251213],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '617',
      balloonContent:
        'Арт-пекарня MAKE ART CAKE, ул. Гагаринская 19<p><a href="https://vegago.ru/cafe/artpekarnya-make-art-cake.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Арт-пекарня MAKE ART CAKE, ул. Гагаринская 19',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.95860194, 30.29915944],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '873',
      balloonContent:
        'Зелёный сад <br/>Ижорская, 3<p><a href="https://vegago.ru/cafe/zelyonyij-sad.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Зелёный сад <br/>Ижорская, 3',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.94295759, 30.34726422],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '971',
      balloonContent:
        'VEGA<p><a href="https://vegago.ru/cafe/vega.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'VEGA',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.91729872, 30.34377876],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1064',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/the-terka-kafe/-vegan-kafe-tyorka.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93839627, 30.36011013],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1138',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/bar-tasty-i-healthy.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.96335865, 30.28783168],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1139',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/panorama-cafe.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.96643934, 30.31141246],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1140',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/vegetarianskoe-kafe-lenivyij-oxotnik.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92039273, 30.34270078],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1183',
      balloonContent:
        'Lazy Vegan, ул.Марата д.74 (Вход через арку, код 239#)<p><a href="https://vegago.ru/cafe/lazy-vegan.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Lazy Vegan, ул.Марата д.74 (Вход через арку, код 239#)',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93566909, 30.35918486],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1207',
      balloonContent:
        'Вегетарианское кафе "Ра-Свет"<p><a href="https://vegago.ru/cafe/restoran-ra-svet.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Вегетарианское кафе "Ра-Свет"',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92589898, 30.31985662],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1321',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/bodhi-cafe.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92814451, 30.31239162],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1338',
      balloonContent:
        'МИР веган кафе, Бертгольд Центр, Гражданская ул. 13-15<p><a href="https://vegago.ru/cafe/mir-vegan-kafe.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'МИР веган кафе, Бертгольд Центр, Гражданская ул. 13-15',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92200276, 30.35557364],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1359',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/gorizontal-etichnaya-kuxnya.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.85015614, 30.28215433],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1448',
      balloonContent:
        'Веганский ресторан Green Heart<p><a href="https://vegago.ru/cafe/veganskij-restoran-green-heart.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Веганский ресторан Green Heart',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93839627, 30.36011013],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1459',
      balloonContent:
        ' Адрес: ул. Восстания 24/27, арка, окошко напротив бара hophead<p><a href="https://vegago.ru/cafe/vegan-kabina.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent:
        ' Адрес: ул. Восстания 24/27, арка, окошко напротив бара hophead',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.93982062, 30.34481182],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1481',
      balloonContent:
        '#ЯвКофе, Моховая улица, 39 <p><a href="https://vegago.ru/cafe/yavkofe.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '#ЯвКофе, Моховая улица, 39 ',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.92576821, 30.35155817],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1532',
      balloonContent:
        'Кафе Healthy Conscience<p><a href="https://vegago.ru/cafe/kafe-healthy-conscience.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: 'Кафе Healthy Conscience',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.94256999, 30.35852909],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1557',
      balloonContent:
        ' ЕСТЬ3 (Есть в Кубе). <p><a href="https://vegago.ru/cafe/est3-(est-v-kube).html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: ' ЕСТЬ3 (Есть в Кубе). ',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [59.95712906, 30.29129],
    },
    properties: {
      imageClass: 'search-map-result-view',
      modx_id: '1558',
      balloonContent:
        '<p><a href="https://vegago.ru/cafe/all-greens-wellness-bar.html" target="_blank">Подробнее</a></p>',
      hintContent: '',
      clusterCaption: '',
      iconContent: '',
    },
    options: {
      preset: 'islands#darkGreenStretchyIcon',
    },
  },
];
